import React, { useState } from "react"
import { useShoppingCart } from 'use-shopping-cart'
import ProductCardPurchase from "./ProductCardPurchase"
import ProductCardHasPurchase from "./ProductCardHasPurchase"
import { StyledProductListItem } from "./productArchive"

const PrimaryProductCardPurchased = props => {

  const [processing, setProcessing] = useState(false);
  const { addItem } = useShoppingCart()
  const { 
    id,
    featuredImage, 
    isAuthenticated, 
    roles, 
    user
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  console.log('PrimaryProductCardPurchased isAuthenticated',isAuthenticated)
  return (
    isAuthenticated && (roles?.includes('The Essential Mindfulness Collection')) && id == 'cG9zdDo2OTE0' ||
    isAuthenticated && (roles?.includes('Permission Reset Anxiety Mindfulness Collection')) && (id == 'cG9zdDo2OTE0' || id == 'cG9zdDo3MjM0') ||
    isAuthenticated && (roles?.includes('Chakra Balancing Collection')) && id == 'cG9zdDo2OTcx' ||
    isAuthenticated && (roles?.includes('QT Balancing Structure')) && id == 'cG9zdDo2MzQ4' ||
    isAuthenticated && (roles?.includes('QT Brain Healing')) && id == 'cG9zdDo2MzU0' ||
    isAuthenticated && (roles?.includes('QT Chakra Balancing')) && id == 'cG9zdDo2MzY1' ||
    isAuthenticated && (roles?.includes('QT Morning Meditation')) && id == 'cG9zdDo2MzU4' ||
    isAuthenticated && (roles?.includes('Self Healing Mastery')) && id == 'cG9zdDo3NzQz' ||
    isAuthenticated && (roles?.includes('Permission to Reset Sleep')) && id == 'cG9zdDo3NzY5' ||
    isAuthenticated && (roles?.includes('Permission to Reset Anxiety')) && id == 'cG9zdDo3MjM0' ||
    isAuthenticated && (roles?.includes('Permission to Reset Sleep Anxiety')) && (id == 'cG9zdDo3MjM0' || id == 'cG9zdDo3NzY5') ||
    isAuthenticated && (roles?.includes('Rermission Reset')) && id == 'cG9zdDo3ODE2' ||
    isAuthenticated && (roles?.includes('Permission Bonus')) && id == 'cG9zdDo3OTI5' ||
    isAuthenticated && (roles?.includes('Spoon Therapy')) && id == 'cG9zdDo5NjIx' ||
    isAuthenticated && (roles?.includes('Your Miraculous Life Club - Round 1')) && id == 'cG9zdDo5NjIz' ||
    isAuthenticated && (roles?.includes('Healing Tuning Blueprint')) && id == 'cG9zdDo5Nj4x' ||
    id == 'cG9zdDo3Mzcz' ?
    // has purchased 
    <><StyledProductListItem><ProductCardHasPurchase data={props} id={id} user={user}/></StyledProductListItem></>
     : 
     <>
      
     </>
  );
}

export default PrimaryProductCardPurchased
