import React, { useEffect, useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import {
  StyledProductList,
  StyledProductListItem,
} from "./Products/productArchive"
import PrimaryProductCard from "./Products/PrimaryProductCard"
import PermissionBonusCard from "./Products/PermissionBonusCard"
import PermissionResetNervousSystem from "./Products/PermissionResetNervousSystem"
import PermissioningPractitioner from "./Products/PermissioningPractitioner"
import PermissioningManifestingCard from "./Products/PermissioningManifesting"
import SpoonTherapy from "./Products/SpoonTherapy"
import CommandHealing from "./Products/CommandHealing"
import FtmjHealingTuningBlueprint from "./Products/FmtjHealingTuningBlueprint"
import MorningDailyIntention from "./Products/MorningDailyIntention"
import YourMiraculousLifeClub from "./Products/YourMiraculousLifeClub"
import PermissionResetNervousSystemJuly2023Workshop from "./Products/PermissionResetNervousSystemJuly2023Workshop"
import PrimaryProductCardPurchased from "./Products/PrimaryProductCardPurchased"

const ProductListPurchased = ({ posts, pageType, padding, type, roles, isAuthenticated, user }) => {
  const [countryCode, setCountryCode] = useState("") // store token
  const identity = useIdentityContext()
  useEffect(() => {
    fetch('/geolocation',{
    headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    })
    .then(function(response){
        return response.json();
    })
    .then(function(geoLocationData) {        
        setCountryCode(geoLocationData?.geo?.country?.code)
    })
}, [])
  return (
    
    <StyledProductList pageType={pageType} padding={padding}>

{isAuthenticated && (roles?.includes('Morning Daily Intention')) &&
            <StyledProductListItem key='morning-daily-intention' type='morning-daily-intention'>
              <MorningDailyIntention
                  title="Morning Daily Intention"
                  id="cG9zdDo3OTI5"
                  excerpt='Watch the recording of the Spoon Therapy Essentials is taught by Kaye Lee, the creator of the method.'
                  featuredImage='/images/spoon-therapy-min.jpg'
                />
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Your Miraculous Life Club - Round 1')) &&
            <StyledProductListItem key='Your-Miraculous-Life-Club' type='morning-daily-intention'>
              <YourMiraculousLifeClub
                  title="Your Miraculous Life Club"
                  id="cG9zdDo3OTI5"
                  excerpt='Watch the replays of the Your Miraculous Life Club.'
                  featuredImage='https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png'
                />
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Command Healing')) &&
            <StyledProductListItem key='command-healing' type='command-healing'>
              <CommandHealing
                  title="Command Healing"
                  id="cG9zdDo4FDkz"
                  excerpt='Watch the recording of the Command Healing for Distress & Trauma taught by Kaye Lee, the creator of the method.'
                  featuredImage='/images/command-healing-min.jpg'
                />
            </StyledProductListItem>
          }


{isAuthenticated && (roles?.includes('Spoon Therapy')) &&
            <StyledProductListItem key='spoon-therapy' type='spoon-therapy'>
              <SpoonTherapy
                  title="Spoon Therapy"
                  id="cG9zdDo3OTI5"
                  excerpt='Watch the recording of the Spoon Therapy Essentials is taught by Kaye Lee, the creator of the method.'
                  featuredImage='/images/spoon-therapy-min.jpg'
                />
            </StyledProductListItem>
          }
{isAuthenticated && (roles?.includes('Permission Bonus')) &&
            <StyledProductListItem key='sss' type='pat-bonus'>
              <PermissionBonusCard
                  title="Permission Bonus audios"
                  id="cG9zdDo3OTI5"
                  excerpt='Listen to these 12 permissions; Boundless Gratitude, Eat Healthy Without Feeling Deprived, Mind, Body &amp; Spirit In Sync, Self Confident In Any Situation, Your Body Is Your Temple plus others'
                  featuredImage='/images/permissioning-buttefly.jpg'
                />  
                
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Permission Reset Your Nervous System Online Audio')) &&
            <StyledProductListItem key='a' type='permission-to-reset-nervous-system'>
              <PermissionResetNervousSystem
                  title="Permission to Reset your Nervous System"
                  id="cG9zdDo3OTI5"
                  excerpt='Audio recording from the online workshop with Kaye &amp; Karina.'
                  featuredImage='/images/permissioning-buttefly.jpg'
                />  
                
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Permission Reset Your Nervous System Online July 2023 Workshop')) &&
            <StyledProductListItem key='a' type='permission-to-reset-nervous-system'>
              <PermissionResetNervousSystemJuly2023Workshop
                  title="Permission to Reset your Nervous System"
                  id="cG9zdDo3OTI5"
                  excerpt='Recording from the online workshop with Karina in July 2023.'
                  featuredImage='/images/permissioning-buttefly.jpg'
                />  
                
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Permissioning Practitioner')) &&
            <StyledProductListItem key='practitioner' type='permissioning-practitioner'>
              <PermissioningPractitioner
                  title="Permissioning Practitioner Program"
                  id="cG9zdDo3OTI5"
                  excerpt='Video recording from the online workshop with Kaye &amp; Karina.'
                  featuredImage='/images/permissioning-buttefly.jpg'
                /> 
            </StyledProductListItem>
          }

{isAuthenticated && (roles?.includes('Permissioning Manifesting')) &&
            <StyledProductListItem key='manifesting' type='permissioning-manifesting'>
              <PermissioningManifestingCard
                  title="Permissioning Manifesting"
                  id="cG9zdDo3OTI5"
                  excerpt='Audio recording from the Permissioning™ Manifesting webinar with Karina.'
                  featuredImage='/images/permissioning-manifesting.jpg'
                /> 
            </StyledProductListItem>
          }
      {posts.map((post, index) => {
          return(
            <>
              {!post.node.productData.hideonarchive &&
                // <StyledProductListItem key={post.node.id} type={type}>
                  <PrimaryProductCardPurchased
                      title={post.node.title}
                      id={post.node.id}
                      uri={post.node.uri}
                      excerpt={post.node.excerpt}
                      featuredImage={post.node.featuredImage}
                      pricefull={countryCode === 'US' ? post.node.productData.pricefullusd : post.node.productData.pricefull}
                      pricesale={countryCode === 'US' ? post.node.productData.pricesaleusd : post.node.productData.pricesale}
                      hidesale={post.node.productData.hidesale}
                      amazonLink={post.node.productData.linkToAmazon}
                      stripefullprice={countryCode === 'US' ? post.node.productData.stripefullpriceusd : post.node.productData.stripefullprice}
                      stripesaleprice={countryCode === 'US' ? post.node.productData.stripesalepriceusd : post.node.productData.stripesaleprice}
                      productTag={post.node.productsTag.nodes[0]?.slug}
                      currency={countryCode === 'US' ? '$' : '£'}
                      isAuthenticated={isAuthenticated}
                      roles={roles}
                      user={user}
                    /> 
                // </StyledProductListItem>
              }
            </>
          )
      })}

{isAuthenticated && (roles?.includes('healing-tuning-blueprint') || isAuthenticated.app_metadata?.roles?.includes('healing-tuning-blueprint')) &&
            <StyledProductListItem key='healing-tuning-blueprint' type='healing-tuning-blueprint'>
              <FtmjHealingTuningBlueprint
                  title="Human Tuning Blueprint"
                  id="cG9zdDo3OTI5"
                  excerpt='Your human tuning blueprint audios.'
                  featuredImage='/images/human-tuning-blueprint-min.jpg'
                />
            </StyledProductListItem>
          }
    </StyledProductList>
  )
}

export default ProductListPurchased
